import { Container, Box } from "@chakra-ui/react"
import { useState } from "react"

import FooterPlayer from "./footer-audio"

import RadioStations from "./tab-stations"


import {
	useLocation
} from "react-router-dom";
import React from "react"


const Main = () => {


	const [streamingURL, setStreamingURL] = useState('')
	const [nowPlaying, setNowPlaying] = useState('')

	let location = useLocation();

	return (

		<Container maxW='container.md' mb={20} centerContent>

			{
				location.pathname === '/' &&
				<RadioStations
					setStreamingURL={setStreamingURL}
					setNowPlaying={setNowPlaying}
				/>
			}

			<Box position="fixed" bottom={0} width={'100%'}>

				<FooterPlayer
					streamingURL={streamingURL}
					nowPlaying={nowPlaying}
				/>

			</Box>

		</Container>
	);
}

export default Main;