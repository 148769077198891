import React, { Suspense } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Box, Container } from "@chakra-ui/react";

import Header from '../common/header';

import Main from '../ui/main'

const About = React.lazy(() => import('../common/about'));
const Donate = React.lazy(() => import('../common/donate'));
const Contact = React.lazy(() => import('../common/contact'));
const Changelog = React.lazy(() => import('../common/changelog'));


const AppRoutes = () => {

	return (

		<BrowserRouter>

			<Header />

			<Suspense fallback={
				<Container maxW='container.sm' mt={10} mb={20} centerContent>
					<Box>Loading...</Box>
				</Container>
			}>

				<Routes>

					<Route path="/" element={<> </>} />

					<Route path="/about" element={<About />} />
					<Route path="/donate" element={<Donate />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/changelog" element={<Changelog />} />

				</Routes>

			</Suspense>

			<Main />

		</BrowserRouter>

	);
}

export default AppRoutes;