const features =
	[
		{
			id: 7,
			title: 'Cape Talk',
			url: 'https://23543.live.streamtheworld.com/CAPE_TALK_SC',
			logo: 'logos/cape-talk.png'
		},
		{
			id: 8,
			title: 'BBC World Service',
			url: 'https://stream.live.vc.bbcmedia.co.uk/bbc_world_service',
			logo: 'https://cdn-profiles.tunein.com/s24948/images/logoq.jpg?t=1'
		},
		{
			id: 9,
			title: 'CNN International',
			url: 'https://tunein.streamguys1.com/CNNi',
			logo: 'https://raw.githubusercontent.com/AusIPTV/IPTVLogos/master/CNN_International_Logo.png'
		},
		{
			id: 10,
			title: 'Bloomberg Radio',
			url: 'https://tunein.streamguys1.com/bloomberg991',
			logo: 'https://cdn-profiles.tunein.com/s165740/images/logod.png'
		},
	]

export default features