import { Box, ChakraProvider } from '@chakra-ui/react'

import './App.css';

import AppRoutes from './util/app-routes';


function App() {

	return (

		<ChakraProvider>

			<div className="App">

				<AppRoutes />

			</div>

		</ChakraProvider>
	);
}

export default App;
