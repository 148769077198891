import { Alert, AlertDescription, AlertIcon, AlertTitle, Container, Spinner } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { isMobile } from "react-device-detect"


type Props = {
	streamingURL: string
	nowPlaying: string
}


const FooterPlayer = ({ streamingURL, nowPlaying }: Props) => {

	let refRAP = useRef<AudioPlayer>(null);


	// Handle Error in Changing URL
	const [error, setError] = React.useState(false)
	const handlePlayError = (e: any) => {
		console.log(e)
		setError(true)
		setIsWaiting(false)
	}


	// Track Volume Change
	const [audioVolume, setAudioVolume] = useState<number>(0.5)
	const handleVolumeChanged = () => {
		const reactPlayer = refRAP.current?.audio
		const newVolume = reactPlayer?.current?.volume
		if (newVolume !== undefined) {
			setAudioVolume(newVolume)
		}
	}


	// Show Spinner while waiting for stream to load (UI/UX)
	const [waiting, setIsWaiting] = useState(false)



	return (
		<>

			{
				error &&

				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Playback Error!</AlertTitle>
					<AlertDescription>Please try another station.</AlertDescription>
				</Alert>

			}

			{
				waiting &&
				<Container centerContent>
					<Spinner />
				</Container>
			}


			<AudioPlayer
				ref={refRAP}
				src={streamingURL}
				showJumpControls={false}
				layout="stacked"
				customProgressBarSection={[]}
				customControlsSection={isMobile ? [RHAP_UI.MAIN_CONTROLS] : [RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
				onPlayError={e => handlePlayError(e)}
				onPlay={() => setError(false)}
				onVolumeChange={() => handleVolumeChanged()}
				volume={audioVolume}
				//	header={nowPlaying}
				onWaiting={() => setIsWaiting(true)}
				onPlaying={() => setIsWaiting(false)}
			/>


		</>
	);
}

export default FooterPlayer;