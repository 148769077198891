const features =
	[
		{
			id: 1,
			title: 'Radio 786',
			url: 'https://stream.krypton.co.za/proxy/radio786/stream',
			logo: 'logos/radio786.png'
		},
		{
			id: 2,
			title: 'Salaam Media',
			url: 'https://iceant.antfarm.co.za/Salaam',
			logo: 'logos/salaamedia.png'
		},
		{
			id: 3,
			title: 'Radio Islam',
			url: 'https://listen.radioislam.org.za:8443/radioislam.mp3',
			logo: 'logos/radioislam.png'
		},
		{
			id: 4,
			title: 'CII Radio',
			url: 'https://edge.iono.fm/xice/109_medium.aac',
			logo: 'logos/cii-radio.png'
		},
		{
			id: 5,
			title: 'AlAnsaar',
			url: 'https://al-ansaar.simplestreaming.co.za/listen/al-ansaar_radio/radio.mp3',
			logo: 'logos/alansaar.png'
		}
	]

export default features