import {
	Box,
	Flex,
	HStack,
	Link,
	IconButton,
	Button,
	Menu,
	MenuButton,
	useDisclosure,
	Stack,
	useColorMode,
	Text
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';

import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { FaShareAlt } from 'react-icons/fa';

import { ReactComponent as LogoLight } from '../assets/svg/logo.svg';
import { ReactComponent as LogoDark } from '../assets/svg/logo-dark.svg';



const Links =
	[
		{ "path": "/", "title": "Home" },
		{ "path": "/changelog", "title": "Changelog" },
		{ "path": "/contact", "title": "Contact" },
		{ "path": "/donate", "title": "Donate" },
		{ "path": "/about", "title": "About" },
	]

const handleShare = () => {

	const pageTitle = document.title;
	const pageUrl = "https://muslimradio.app"

	if (navigator.share) {
		navigator
			.share({
				title: pageTitle,
				url: pageUrl
			})
			.then(() => {
				console.log("Shared successfully.");
			})
			.catch(error => {
				console.log("There was an error sharing:", error);
			});
	}
}


export default function Simple() {

	const { colorMode, toggleColorMode } = useColorMode();

	const { isOpen, onOpen, onClose } = useDisclosure();


	const navigate = useNavigate();

	return (
		<>
			<Box px={4}>
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<IconButton
						size={'md'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={isOpen ? onClose : onOpen}
					/>
					<HStack spacing={8} alignItems={'center'}>

						<Box onClick={() => navigate("./")} cursor={"pointer"}>
							{
								colorMode === 'light' &&
								<LogoLight />
							}
							{
								colorMode === 'dark' &&
								<LogoDark />
							}
						</Box>

						<HStack

							spacing={4}
							display={{ base: 'none', md: 'flex' }}>
							{Links.map((link, index) => (
								<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
							))}
						</HStack>
					</HStack>

					<Flex alignItems={'center'}>
						<Menu>
							<MenuButton
								as={Button}
								size={'sm'}
								cursor={'pointer'}
								minW={0}
								onClick={() => handleShare()}
								mr={2}
							>
								<FaShareAlt />
							</MenuButton>

							<MenuButton
								as={Button}
								size={'sm'}
								cursor={'pointer'}
								minW={0}
								onClick={toggleColorMode}>
								{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
							</MenuButton>
						</Menu>
					</Flex>

				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map((link, index) => (
								<Link key={index} as={ReactRouterLink} to={link.path}>{link.title}</Link>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>


		</>
	);
}
