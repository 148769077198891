import { Container, Avatar, AvatarBadge, VStack, Box, Flex, Divider } from '@chakra-ui/react';
import React from 'react';


import RadioStationsListMuslim from '../data/stations-list-muslim'
import RadioStationsListGeneral from '../data/stations-list-general'




type Props = {
	setStreamingURL: Function
	setNowPlaying: Function
}

const RadioStations = ({ setStreamingURL, setNowPlaying }: Props) => {


	const [radioID, setRadioID] = React.useState(0)

	const radioStationSelected = (e: any) => {
		// console.log(e)
		setStreamingURL(e.url)
		setNowPlaying(e.title)
		setRadioID(e.id)
	}


	return (

		<Container maxW='container.sm' mt={10} mb={20} centerContent>

			<Flex wrap={"wrap"} alignItems={"center"} justifyContent={"center"} gap='5'>

				{
					RadioStationsListMuslim.map((item, index) => (

						<Box
							key={index}
							onClick={e => radioStationSelected(item)}
							cursor='pointer'
						>

							<VStack>
								<Avatar
									bg='gray.100'
									name={item.title}
									src={item.logo}
									size='xl'
								>

									{
										radioID === item.id &&
										< AvatarBadge
											bg='green.500'
											boxSize='0.75em'
										/>
									}

								</Avatar>

							</VStack>

						</Box>
					))

				}


			</Flex>

			<Divider mt={10} mb={10} />

			<Flex wrap={"wrap"} alignItems={"center"} justifyContent={"center"} gap='5'>

				{
					RadioStationsListGeneral.map((item, index) => (

						<Box
							key={index}
							onClick={e => radioStationSelected(item)}
							cursor='pointer'
						>

							<VStack>
								<Avatar
									bg='gray.100'
									name={item.title}
									src={item.logo}
									size='xl'
								>

									{
										radioID === item.id &&
										< AvatarBadge
											bg='green.500'
											boxSize='0.75em'
										/>
									}

								</Avatar>

							</VStack>

						</Box>
					))

				}


			</Flex>



		</Container >


	);
}

export default RadioStations;